<template lang="pug">
  input.form-control(
    v-bind  = '$attrs'
    v-on    = 'listeners'
    :class  = '`form-control-${size}`'
  )
</template>


<script>
export default {
  name: 'fi-input',

  inheritAttrs: false,

  props: {
    size: {
      type: String,
      default: 'sm'
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', event.currentTarget.value)
      }
    }
  }
}
</script>
